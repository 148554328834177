import axios from 'axios'
import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const AddNewTracking = ({ show, handleClose }) => {
  const initialFormData = {
    insertDate: new Date().toISOString().slice(0, 10),
    trackingNo: '',
    recipient: '',
    remark: '',
    type: '1',
  }
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/v1/admin/tracking/new', formData)
      if (response.data.success) {
        setFormData(initialFormData)
        handleClose()
      } else {
        throw new Error('Failed to add tracking')
      }
    } catch (error) {
      alert('Failed to submit the form')
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Tracking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='formInsertDate'>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              name='insertDate'
              value={formData.insertDate}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formTrackingNo'>
            <Form.Label>Tracking No</Form.Label>
            <Form.Control
              type='text'
              name='trackingNo'
              value={formData.trackingNo}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formRecipient'>
            <Form.Label>Recipient</Form.Label>
            <Form.Control
              type='text'
              name='recipient'
              value={formData.recipient}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formRemark'>
            <Form.Label>Remark</Form.Label>
            <Form.Control
              type='text'
              name='remark'
              value={formData.remark}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <fieldset>
            <Form.Group className='mb-3'>
              <Form.Label>Type</Form.Label>
              <div className='d-flex align-items-center'>
                <Form.Check
                  type='radio'
                  label='Church/PSO'
                  name='type'
                  id='type1'
                  value='1'
                  className='me-3' // Bootstrap margin-right class for spacing
                  checked={formData.type === '1'}
                  onChange={handleChange}
                />
                <Form.Check
                  type='radio'
                  label='Online Order'
                  name='type'
                  id='type2'
                  value='2'
                  checked={formData.type === '2'}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </fieldset>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={handleSubmit}
          style={{ marginRight: '20px' }}
        >
          Submit
        </Button>

        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddNewTracking
